import React from "react"
import Layout from "../components/layout"
import Carousel from "react-multi-carousel"
import Card from "../components/card"
import { graphql } from "gatsby"
import Date from "../components/date"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

export const query = graphql
`
    query PostsByID($id: String!) {
        mdx(
            id: { eq: $id }
        ){
            body
            frontmatter {
                title
                date(formatString: "DD.MM.YYYY")
                image {
                  childImageSharp{
                    gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                    )
                  }
                }
            }
        },
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { eq: true } } }
          ) {
            nodes {
              id
              excerpt(pruneLength: 250)
              fields {
                slug
              }
              frontmatter {
                title
                date
                cover {
                    childImageSharp{
                      gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
              }
            }
          }
    }
`

const PostPage = ({ data }) => {
    const { frontmatter, body } = data.mdx

    const image = getImage(frontmatter.image)

    return (
        <div id="background-news">
            <Helmet>
            <meta charSet="utf-8" />
            <title>{frontmatter.title}</title>
            </Helmet>
            <Layout>
                <section style={{margin:"5rem 0rem 5rem 0rem"}}>
                    <div className="container text-white text-center">
                        <h1 className="display-3 fw-bold ">LATEST NEWS & ARTICLES</h1>
                    </div>
                </section>
                
                <section style={{marginTop:"15rem"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <GatsbyImage image={image} alt={frontmatter.title} />
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-5 mt-5">
                                <h1 className="gold-color">{frontmatter.title}</h1>
                                <div className="fs-4 my-5">
                                    <MDXRenderer>{body}</MDXRenderer>
                                </div>
                                <p className="text-dark fw-bold lead">{frontmatter.date}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section style={{marginTop:"5rem"}}>
                    <div className="container">
                        <h1 className="text-left gold-color">RECOMMENDED LATEST NEWS & ARTICLES</h1>
                        <div className="container mt-5 p-0">
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlay
                                autoPlaySpeed={5000}
                                centerMode={false}
                                focusOnSelect={false}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                showDots={false}
                                responsive={{
                                desktop: {
                                    breakpoint: {
                                    max: 3000,
                                    min: 1024
                                    },
                                    items: 3,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                    max: 464,
                                    min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                    max: 1024,
                                    min: 464
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 30
                                }
                                }}
                                
                            >
                            {data.allMdx.nodes.map(
                                ({ slug, excerpt, frontmatter, fields }) => {
                                    const cardImage = getImage(frontmatter.cover)

                                    return (
                                        <Card
                                            key={fields.slug}
                                            cardTitle={frontmatter.title}
                                            cardText={excerpt}
                                            cardTime={<Date time={frontmatter.date}/>}
                                            image={cardImage}
                                            link={fields.slug}
                                        />
                                    )
                                }
                            )}
                            </Carousel>
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    )
}

export default PostPage;